<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>校区管理</a-breadcrumb-item>
            <a-breadcrumb-item>在线课程</a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<script>
    export default {
        name:'academy'
    }
</script>

<style lang="scss" scoped>

</style>